'use strict';


const ITEM_LIST_ID = {
	RECOMMENDED_PRODUCTS: 'recommended_products',
	CART: 'cart',
	MINICART: 'minicart',
	PDP: 'product_detail_page',
	QUICKVIEW: 'product_quickview'
};
const ITEM_LIST_NAME = {
	RECOMMENDED_PRODUCTS: 'Recommended products',
	PLP: 'Product Listing Page',
	SRP: 'Search Results Page',
	PDP: 'Product Detail Page',
	QUICKVIEW: 'Product Quickview',
	CART: 'Cart',
	MINICART: 'Minicart'
};
const VIEW_PLP_EVENT = 'viewPLP';
const VIEW_SRP_EVENT = 'viewSRP';
const VIEW_PRODUCT_RECOMMENDATION_CAROUSEL = 'viewProductRecommendationCarousel';

// Spark changes for GTM - GA4: BEGIN
module.exports = function () {
	if (typeof window.dataLayer === 'undefined') {
        window.dataLayer = [];
    }

	/**
	 * Fetches and returns GTM attributes item_list_id and item_list_name based on the page
	 * @returns {Object} - item list data
	 */
	function getGTMItemListNameAndId() {
		var itemList = {};

		var actionPage = $('.page').data('action');
		var queryString = $('.page').data('querystring');

		var isQuickViewModalVisible = $('#quickViewModal').is(':visible');

		if (actionPage === 'Search-Show') {
			if (queryString && queryString !== '') {
				if (queryString.indexOf('cgid=') > -1) {
					itemList.itemListId = queryString.split('cgid=').pop().split('&')[0];
					itemList.itemListName = ITEM_LIST_NAME.PLP;
				} else if (queryString.indexOf('q=') > -1) {
					itemList.itemListId = 'root';
					itemList.itemListName = ITEM_LIST_NAME.SRP;
				}
			}
		} else if (actionPage === 'Product-Show' || actionPage === 'GiftCertificate-Purchase') {
			itemList.itemListId = ITEM_LIST_ID.PDP;
			itemList.itemListName = ITEM_LIST_NAME.PDP;
		} else {
			itemList.itemListId = ITEM_LIST_ID.RECOMMENDED_PRODUCTS;
			itemList.itemListName = ITEM_LIST_NAME.RECOMMENDED_PRODUCTS;
		}

		if (isQuickViewModalVisible) {
			itemList.itemListId = ITEM_LIST_ID.QUICKVIEW;
			itemList.itemListName = ITEM_LIST_NAME.QUICKVIEW;
		}

		return itemList;
	}

    if (window.gtmData) {
        for (var i = 0; i < window.gtmData.length; i++) {
            var gtmDatalayer = JSON.parse(window.gtmData[i]);
			if ([VIEW_PLP_EVENT, VIEW_SRP_EVENT].indexOf(gtmDatalayer.event) !== -1) {
				// Populate the item_list_id and item_list_name
				let itemListDetails = getGTMItemListNameAndId();
				gtmDatalayer.ecommerce.item_list_id = itemListDetails.itemListId;
				gtmDatalayer.ecommerce.item_list_name = itemListDetails.itemListName;
				// Update item_list_id and item_list_name on items[]
				var items = gtmDatalayer.ecommerce.items;
				gtmDatalayer.ecommerce.items = items.map(function (item) {
					item.item_list_id = itemListDetails.itemListId;
					item.item_list_name = itemListDetails.itemListName;
					return item;
				});
			}
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push(gtmDatalayer);
        }
    }

	/**
	 * Custom event for firing GTM accountLogin event
	 */
	$('form.login').on('login:success', function (e, data) {
		if (data.gtmData) {
            window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(data.gtmData);
		}
	});

	/**
	 * Custom event for firing GTM accountRegister event
	 */
	$('form.registration').on('login:register:success', function (e, data) {
		if (data.gtmData) {
            window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(data.gtmData);
		}
	});

	/**
	 * Custom event for firing Add to cart/Remove from cart event
	 * when quantity is modified in cart page
	 */
	$('body').on('product:afterAddToCart product:afterCartQuantityChanges', function (event, data) {
		if (data.gtmData && data.gtmData.ecommerce) {
			let itemListDetails = getGTMItemListNameAndId();
			let eComItems = data.gtmData.ecommerce.items;
			data.gtmData.ecommerce.items = eComItems.map(function (item) {
				item.item_list_id = itemListDetails.itemListId;
				item.item_list_name = itemListDetails.itemListName;
				return item;
			});
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(data.gtmData);
		}
	});

	var cartUpdateQuantity = 'UpdateQuantity';
	var cartRemoveProduct = 'RemoveProductLineItem';

    /**
	 * parses query string
	 * @param {string} search - query string to be parsed
	 * @returns {Object} - json data
	 */
	function parseQuery(search) {
		return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
			return key === '' ? value : decodeURIComponent(value);
		});
	}

	$(document).bind('ajaxComplete', function (event, jqxhr, settings) {
		if (settings.url.toLowerCase().indexOf(cartUpdateQuantity.toLowerCase()) !== -1) {
			var search = jqxhr.responseJSON.queryString;
			var queryObject = parseQuery(search);
			var $originalE = $('div.quantity-form').find('[data-pid="' + queryObject.pid + '"]').parent();
			$originalE.data('currentquantity', queryObject.quantity);
		}
	});

    $(document).bind('ajaxSend', function (event, jqxhr, settings) {
		if (settings.url.toLowerCase().indexOf(cartUpdateQuantity.toLowerCase()) !== -1 || settings.url.toLowerCase().indexOf(cartRemoveProduct.toLowerCase()) !== -1) {
			var searchHr = settings.url;
			var search = searchHr.substr(searchHr.indexOf('?') + 1);
			var queryObject = parseQuery(search);
			var $originalQ = $('div.quantity-form').find('[data-pid="' + queryObject.pid + '"]').parent();
			settings.url += '&originalq=' + parseInt($originalQ.data('currentquantity'), 10);
		}
	});

	/**
	 * This custom event is fired when customer clicks on load more from PLP or SRP pages
	 * Function will collate all the item details to be pushed as a view_item_list event
	 */
	$('body').on('gtm:searchShowMoreSuccess', function () {
		$('.load-more-gtm-viewitemlist').each(function (index, element) {
			var viewItemListEventPushed = $(element).data('gtm-viewitemlist-event-pushed');
			if (viewItemListEventPushed) {
				return true;
			}

			var itemListDetails = getGTMItemListNameAndId();

			var datalayerObj = {};
			if (itemListDetails.itemListName === ITEM_LIST_NAME.PLP) {
				datalayerObj.event = VIEW_PLP_EVENT;
			} else {
				datalayerObj.event = VIEW_SRP_EVENT;
			}
			datalayerObj.ecommerce = {};
			datalayerObj.ecommerce.items = [];

			// Set item_list_id and item_list_name
			datalayerObj.ecommerce.item_list_id = itemListDetails.itemListId;
			datalayerObj.ecommerce.item_list_name = itemListDetails.itemListName;

			var gtmViewItemListData = $(element).data('gtm-viewitemlist');
			var pageSize = parseInt($(element).data('page-size'), 0);
			var pageNumber = parseInt($(element).data('page-number'), 0);

			var ecommerceItems = gtmViewItemListData.map(function (productRecord, productIndex) {
				var productID = productRecord.productID;
				var productTileIdentifier = 'div.product[data-pid="' + productID + '"]';
				var ecommerceItem = $(productTileIdentifier).data('gtmjsondata');
				// Update index, item_list_id and item_list_name
				ecommerceItem.index = (pageSize * pageNumber) + productIndex;
				ecommerceItem.item_list_id = itemListDetails.itemListId;
				ecommerceItem.item_list_name = itemListDetails.itemListName;
				return ecommerceItem;
			});
			datalayerObj.ecommerce.items = ecommerceItems;

			// Push view_item_list event
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(datalayerObj);

			// Update the data attribute to indicate that the event was pushed for these set of products
			$(element).data('gtm-viewitemlist-event-pushed', true);

			return true;
		});
	});

	/**
	 * GTM removeFromCart event fired when product is removed from cart
	 */
    $('body').on('removeModal:removeItem', function (e, data) {
		if (data.gtmData && data.gtmData.ecommerce) {
			var itemList = {};
			var actionPage = $('.page').data('action');
			if (actionPage === 'Cart-Show') {
				itemList.itemListId = ITEM_LIST_ID.CART;
				itemList.itemListName = ITEM_LIST_NAME.CART;
			} else {
				itemList.itemListId = ITEM_LIST_ID.MINICART;
				itemList.itemListName = ITEM_LIST_NAME.MINICART;
			}

			let eComItems = data.gtmData.ecommerce.items;
			data.gtmData.ecommerce.items = eComItems.map(function (item) {
				item.item_list_id = itemList.itemListId;
				item.item_list_name = itemList.itemListName;
				return item;
			});

			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(data.gtmData);
		}
	});


	/**
	 * GTM events submitCheckoutShipping or submitCheckoutBilling is fired based
	 * on if customer submitted shipping or billing section within checkout
	 */
	$('body').on('checkout:updateCheckoutView', function (event, data) {
		if (typeof data.order.gtmData !== 'undefined') {
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(JSON.parse(data.order.gtmData));
		}
	});

	/**
	 * Pushes GTM datalayer for  View Product Recommendation Carousels
	 */
	$('body').on('carousels:viewRecommendedProducts', function (event, data) {
		var productsArray = [];

		$(data.selector).each(function (index) {
			var json = JSON.parse(this.dataset.gtmjsondata) || {};
			json.index = index + 1;
			json.item_list_id = ITEM_LIST_ID.RECOMMENDED_PRODUCTS;
			json.item_list_name = ITEM_LIST_NAME.RECOMMENDED_PRODUCTS;
			productsArray.push(json);
		});

		if (productsArray.length > 0) {
			var viewItemLists = {
				event: VIEW_PRODUCT_RECOMMENDATION_CAROUSEL,
				ecommerce: {
					item_list_id: ITEM_LIST_ID.RECOMMENDED_PRODUCTS,
					item_list_name: ITEM_LIST_NAME.RECOMMENDED_PRODUCTS,
					items: productsArray
				}
			};
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(viewItemLists);
		}
	});


	/**
	 * GTM selectProduct event trigger when user selects a product from
	 *  - PLP
	 *  - SRP
	 *  - Recommendation carousel
	 */
	$('body').on('click', '.product-tile .image-container a:not(.quickview, .wishlistTile), .product-tile .swatches, .product-tile .pdp-link, .full-pdp-link', function (e) {
		var $target = $(e.target);
		var $el = $(this);
		var productData = null;
		var productArray = [];
		var itemList = getGTMItemListNameAndId();

		var $closestDataElement = $el.closest('[data-gtmjsondata]');
		if ($closestDataElement.length > 0) {
			productData = JSON.parse($closestDataElement[0].dataset.gtmjsondata);
		}

		if ($target.hasClass('full-pdp-link')) {
			$closestDataElement = $('.modal-content .product-quickview');
			productData = JSON.parse($closestDataElement[0].dataset.gtmjsondata);
		}

		if (productData) {
			productData.item_list_id = itemList.itemListId;
			productData.item_list_name = itemList.itemListName;
			if (!productData.quantity) {
				productData.quantity = 1;
			}
			if (productData.currency) {
				delete productData.currency;
			}
			productArray.push(productData);
		}

		var selectProductDL = {
			event: 'selectProduct',
			ecommerce: {
				item_list_id: itemList.itemListId,
				item_list_name: itemList.itemListName,
				items: productArray
			}
		};

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push(selectProductDL);
	});
};
// Spark changes for GTM - GA4: END
